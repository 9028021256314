import { PlaceAdPlansId, PlaceAdTotalTabs, PlaceAdType, PlaceAdValuesEndPoint, SectionType } from '@core/enums';
import { PlaceAdFields, Tenant } from '@core/models/place-ad.model';

export const PLACE_AD_FIELDS_PROPERTIES = [
	{
		label: 'n150',
		paramName: PlaceAdType.COUNTY,
		valuesEndpoint: PlaceAdValuesEndPoint.COUNTY,
		childReferenceParamName: PlaceAdType.COUNTY_AREA,
	},
	{
		label: 'n150',
		paramName: PlaceAdType.COUNTY_AREA,
		valuesEndpoint: PlaceAdValuesEndPoint.COUNTY_AREA,
		parentReferenceParamName: PlaceAdType.COUNTY,
		queryParamName: PlaceAdType.COUNTY,
	},
	{
		label: 'n152',
		paramName: PlaceAdType.PRICE,
		fieldType: 'input-number',
	},

	{
		label: 'n663',
		paramName: PlaceAdType.STAND,
		valuesEndpoint: PlaceAdValuesEndPoint.STAND,
		fieldType: 'dropdown',
		placeholder: 'n3080',
		childReferenceParamName: PlaceAdType.PROPERTY_UNIT,
	},
	{
		label: 'n3265',
		paramName: PlaceAdType.PARKING_ACCESS,
	},
	{
		label: 'n3263',
		paramName: PlaceAdType.PARKING_COUNT,
	},

	{
		label: 'n3253',
		paramName: PlaceAdType.SINGLE_BEDROOM_COUNT,
		fieldType: 'radio',
	},
	{
		label: 'n3254',
		paramName: PlaceAdType.DOUBLE_BEDROOM_COUNT,
		fieldType: 'radio',
	},
	{
		label: 'n3255',
		paramName: PlaceAdType.SUITE_BEDROOM_COUNT,
		fieldType: 'radio',
	},
	{
		label: 'n659',
		paramName: PlaceAdType.BATHROOM_COUNT,
		fieldType: 'radio',
	},
	{
		label: 'n466',
		paramName: PlaceAdType.PROPERTY_SIZE,
		fieldType: 'input-text',
		matSuffix: 'n684',
		placeholder: 'n3431',
	},
	{
		label: 'n3111',
		paramName: PlaceAdType.AVAILABLE_FROM,
		fieldType: 'date',
		placeholder: 'n3112',
	},

	{
		label: 'n3032',
		paramName: PlaceAdType.RENTAL_PERIOD,
		valuesEndpoint: PlaceAdValuesEndPoint.RENTAL_PERIOD,
		fieldType: 'dropdown',
		placeholder: 'n3081',
	},
	{
		label: 'n3032',
		paramName: PlaceAdType.LEASE_TERM,
		valuesEndpoint: PlaceAdValuesEndPoint.LEASE_TERM,
		fieldType: 'dropdown',
		placeholder: 'n3081',
	},
	{
		label: 'n467',
		paramName: PlaceAdType.FURNISHING,
		fieldType: 'dropdown',
		valuesEndpoint: PlaceAdValuesEndPoint.FURNISHING,
		placeholder: 'n3082',
	},
	{
		label: 'n3027',
		paramName: PlaceAdType.PROPERTY_EXTRAS,
		fieldType: 'checkbox',
		valuesEndpoint: PlaceAdValuesEndPoint.PROPERTY_EXTRAS,
	},
	{
		label: 'n3242',
		paramName: PlaceAdType.OWNER_OCCUPIED,
		fieldType: 'checkbox',
	},

	{
		label: 'n3243',
		paramName: PlaceAdType.TEANANT_PREFERENCE,
		valuesEndpoint: PlaceAdValuesEndPoint.TENANT_PREFERENCES,
		fieldType: 'radio',
	},
	{
		label: 'n3247',
		paramName: PlaceAdType.ONEPLUS_PERSON_ALLOWED,
		fieldType: 'checkbox',
	},
	{
		label: 'n3248',
		paramName: PlaceAdType.OTHER_TENANTS,
		valuesEndpoint: PlaceAdValuesEndPoint.OTHER_TENANTS_COUNT,
		fieldType: 'dropdown',
		placeholder: 'n3249',
	},

	{
		label: 'n3440',
		paramName: PlaceAdType.PROPERTY_UNIT,
		fieldType: 'dropdown',
		selectedValue: '1',
		valuesEndpoint: PlaceAdValuesEndPoint.PROPERTY_UNITS,
		parentReferenceParamName: PlaceAdType.STAND,
		queryParamName: PlaceAdType.STANDS,
	},
	{
		label: 'n3430',
		paramName: PlaceAdType.BER_TYPE,
		valuesEndpoint: PlaceAdValuesEndPoint.BER,
		fieldType: 'dropdown',
		placeholder: 'n3444',
	},
	{
		label: 'n3446',
		paramName: PlaceAdType.BER_NUMBER,
		fieldType: 'input-text',
		placeholder: 'n3443',
		optional: true,
	},
	{
		label: 'n3629',
		paramName: PlaceAdType.TAX_DESIGNATION,
		valuesEndpoint: PlaceAdValuesEndPoint.TAX_DESIGNATIONS,
		fieldType: 'dropdown',
		placeholder: 'n3444',
		optional: true,
	},
] as PlaceAdFields[];

export const PLACE_AD_PROPERTIES_FIELDS_ORDER = {
	location: [PlaceAdType.COUNTY, PlaceAdType?.COUNTY_AREA],
	price: [PlaceAdType?.PRICE],
	details: [
		PlaceAdType.STAND,
		PlaceAdType.PARKING_ACCESS,
		PlaceAdType.PARKING_COUNT,
		PlaceAdType.SINGLE_BEDROOM_COUNT,
		PlaceAdType.DOUBLE_BEDROOM_COUNT,
		PlaceAdType.SUITE_BEDROOM_COUNT,
		PlaceAdType.BATHROOM_COUNT,
		PlaceAdType.PROPERTY_SIZE,
		PlaceAdType.AVAILABLE_FROM,
		PlaceAdType.LEASE_TERM,
		PlaceAdType.FURNISHING,
	],
	amenities: [PlaceAdType.PROPERTY_EXTRAS],
};

export const COUPON_PRICE = 0.0;
export const BRANDED_COUPON_CODE = 'IrishHouseMate_BrandedFeatured';
export const FEATURED_AD_PLAN_IDS = [PlaceAdPlansId.FEATURED_AD, PlaceAdPlansId.BRANDED_FEATURED, PlaceAdPlansId.BRANDED_PLUS];
export const INDIVIDUAL_COUPON_CODE = 'FindQo_88991';

export const SECTION_LIST = [SectionType.PROPERTIES_FOR_RENT, SectionType.PROPERTIES_FOR_SALE];
export const TOTAL_STEPS = (sectionReference: string) => {
	return sectionReference === SectionType.PROPERTIES_FOR_RENT ? PlaceAdTotalTabs.PROPERTIES_FOR_RENT_TOTAL_TAB : PlaceAdTotalTabs.PROPERTIES_FOR_SALE_TOTAL_TAB;
};

export const SHOW_BEDROOM_STAND_LIST = [
	'apartments',
	'houses',
	'holiday-homes',
	'short-term',
	'detached-house',
	'semi-detached-house',
	'terraced-house',
	'end-of-terrace-house',
	'townhouse',
	'duplex',
	'bungalow',
];
export const PARKING_STAND_ID = '5';
export const WEEK_DAYS = [0, 1, 2, 3, 4, 5, 6];
