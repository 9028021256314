import { Injectable } from '@angular/core';
import { Agent } from '@core/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AgentsState {
	private readonly _agentList$ = new BehaviorSubject<Agent[]>(undefined);

	get agentList$(): Observable<Agent[]> {
		return this._agentList$.asObservable();
	}

	setAgentList(agentList: Agent[]) {
		this._agentList$.next(agentList);
	}

	getAgentList(): Agent[] {
		return this._agentList$.getValue();
	}
}
