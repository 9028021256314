import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PlaceAdType } from '@core/enums';
import { PlaceAd } from '@core/models';
import { BehaviorSubject, Observable, debounceTime, pairwise, startWith } from 'rxjs';

interface DetailBadge {
	icon: string;
	property: PlaceAdType;
}

@Injectable({
	providedIn: 'root',
})
export class PlaceAdService {
	constructor(
		private fb: FormBuilder,
		private location: Location
	) {}

	private _tabIndex$ = new BehaviorSubject<number>(0);
	private _stepsEnableStatus$ = new BehaviorSubject<number>(0);
	private _currentSellerId$ = new BehaviorSubject<string>('');
	private _isNextButtonDisabled = new BehaviorSubject<boolean>(false);
	private _totalTabs$ = new BehaviorSubject<number>(7); //to dynamically update the tabs(amenities tab)

	public isRentResidentialStandSelected: boolean = false;
	public stand: string = '0';

	get totalTabs$(): Observable<number> {
		return this._totalTabs$.asObservable();
	}

	getTotalTabs(): number {
		return this._totalTabs$.getValue();
	}

	setTotalTabs(value: number): void {
		this._totalTabs$.next(value);
	}

	get isNextButtonDisabled$(): Observable<boolean> {
		return this._isNextButtonDisabled.asObservable();
	}

	setIsNextButtonDisabled(value: boolean): void {
		this._isNextButtonDisabled.next(value);
	}

	private placeAdFieldsData: PlaceAd = {};

	private readonly propertyDetailsBadge = [
		{ icon: 'bedroom', property: PlaceAdType.BEDROOM_COUNT },
		{ icon: 'shower', property: PlaceAdType.BATHROOM_COUNT },
		{ icon: 'select_all', property: PlaceAdType.PROPERTY_SIZE },
	];

	updatePlaceAdFormData(form: FormGroup): PlaceAd {
		const formGroupValues = form.controls;

		Object.keys(formGroupValues).forEach((key) => {
			if (key === PlaceAdType.AVAILABLE_FROM) {
				this.placeAdFieldsData[key] = Math.floor(new Date(formGroupValues[key].value).getTime() / 1000);
			} else if (key === PlaceAdType.OWNER_OCCUPIED || key === PlaceAdType.ONEPLUS_PERSON_ALLOWED) {
				this.placeAdFieldsData[key] = formGroupValues[key].value ? '1' : '0';
			} else if (key === PlaceAdType.IS_PRICE_ON_APPLICATION) {
				this.placeAdFieldsData[key] = formGroupValues[key].value ? '1' : '0';
			} else {
				if (formGroupValues[key].value) {
					this.placeAdFieldsData[key] = formGroupValues[key].value;
				} else {
					this.deleteIndividualControl(key);
				}
			}
		});

		return this.placeAdFieldsData;
	}

	addIndividualControlData(control, value): void {
		this.placeAdFieldsData[control] = value;
	}

	addAgentsId(agentId: string): void {
		this.placeAdFieldsData['agentId'] = agentId;
	}

	removeFormData(key: string): void {
		delete this.placeAdFieldsData[key];
	}

	addDisplayAddress(displayAddress: string): void {
		this.placeAdFieldsData['displayAddress'] = displayAddress;
	}

	addEirCode(eirCode: string): void {
		this.placeAdFieldsData['eirCode'] = eirCode;
	}

	addCountyData(county: string, countyArea: string): void {
		this.placeAdFieldsData['countyArea'] = countyArea;
		this.placeAdFieldsData['county'] = county;
	}

	deleteCountyData(): void {
		delete this.placeAdFieldsData['countyArea'];
		delete this.placeAdFieldsData['county'];
	}

	deleteIndividualControl(controlName: string): void {
		if (this.placeAdFieldsData?.[controlName]) {
			delete this.placeAdFieldsData?.[controlName];
		}
	}

	getPlaceAdData(): PlaceAd {
		return this.placeAdFieldsData;
	}

	getPlaceAdIndividualData(property: string) {
		return this.placeAdFieldsData?.[property];
	}

	onBack(): void {
		if (this.getTabIndex() === 0) {
			this.location.back();
			return;
		}

		this.setTabIndex(this.getTabIndex() - 1);
	}

	//to switch between tabs in place ad steps
	getTabIndex$(): Observable<number> {
		return this._tabIndex$.asObservable();
	}

	setTabIndex(index): void {
		this._tabIndex$.next(index);
	}

	getTabIndex(): number {
		return this._tabIndex$.getValue();
	}

	// end of tabs function

	getPropertiesDetailsBadge(): DetailBadge[] {
		return this.propertyDetailsBadge.slice();
	}

	resetPlaceAdData() {
		this.placeAdFieldsData = {};
	}

	get stepsEnableStatus$(): Observable<number> {
		return this._stepsEnableStatus$.asObservable();
	}

	setStepsEnableStatus(value: number) {
		this._stepsEnableStatus$.next(value);
	}

	getStepsEnableStatus(): number {
		return this._stepsEnableStatus$.getValue();
	}

	markFormFieldTouched(form: FormGroup): void {
		Object.keys(form.controls).forEach((key) => {
			const CONTROL = form.get(key);
			CONTROL.markAsTouched({ onlySelf: true });
		});
	}

	formValidationObservable(form: FormGroup): Observable<[{}, {}]> {
		return form.valueChanges.pipe(debounceTime(100), startWith(''), pairwise());
	}

	getCurrentSellerId$(): Observable<string> {
		return this._currentSellerId$.asObservable();
	}

	setCurrentSellerId(controlName): void {
		this._currentSellerId$.next(controlName);
	}

	getCurrentSellerId(): string {
		return this._currentSellerId$.getValue();
	}

	markFormArrayAsTouched(field: FormArray): void {
		field.controls.forEach((control: FormGroup) => {
			this.markFormGroupControlsAsTouched(control);
		});
	}

	private markFormGroupControlsAsTouched(formGroup: FormGroup): void {
		Object.values(formGroup.controls).forEach((control) => {
			if (control instanceof FormControl) {
				control.markAsTouched();
			} else if (control instanceof FormGroup) {
				this.markFormGroupControlsAsTouched(control);
			}
		});
	}

	checkForDataUpdates(fromData: {}): boolean {
		let updated = false;
		const PREVIOUS_DATA = this.getPlaceAdData();

		for (let key in fromData) {
			if (key === PlaceAdType.AVAILABLE_FROM) {
				if (Math.floor(new Date(fromData[key]).getTime() / 1000) !== PREVIOUS_DATA[key]) {
					updated = true;

					break;
				}
			} else {
				if (fromData[key] != PREVIOUS_DATA[key]) {
					updated = true;

					break;
				}
			}
		}

		return updated;
	}

	//to update tab index if we know which no the correct tab index ie tabs upto amenities
	directTabIndexUpdate(index: number): void {
		const CURRENT_ENABLE_INDEX = this.getStepsEnableStatus();
		const CURRENT_INDEX = this.getTabIndex();

		if (CURRENT_ENABLE_INDEX === CURRENT_INDEX) {
			this._stepsEnableStatus$.next(index);
		}

		this._tabIndex$.next(index);
	}
}
