import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, filter, firstValueFrom, map, of, switchMap } from 'rxjs';

import { FiltersApi } from '@core/data-access/apis/filters.api';
import { PlaceAdApi } from '@core/data-access/apis/place-ad.api';
import { PlaceAdState } from '@core/data-access/states/place-ad.state';
import { SectionsState } from '@core/data-access/states/sections.state';
import {
	AdPaymentStatus,
	AdStatus,
	AisleType,
	PlaceAdPlansId,
	PlaceAdTabIndex,
	PlaceAdType,
	PlaceAdValuesEndPoint,
	SectionType,
	SellerType,
} from '@core/enums';
import { Ad, Aisle, ClientUser, CommonItem, PageMetaData, Section } from '@core/models';

import { Params } from '@angular/router';
import { AgentsApi } from '@core/data-access/apis/agents.api';
import { ProfileApi } from '@core/data-access/apis/profile.api';
import { ProfileState } from '@core/data-access/states/profile.state';
import {
	Agent,
	Brochure,
	Contact,
	Coupon,
	EditAdResponseData,
	Ericode,
	PaymentResponse,
	PlaceAdFields,
	PlaceAdFieldsSections,
	PlaceAdPaymentParams,
	PlanTiers,
	ReOrderImage,
	VerifyPayment,
} from '@core/models/place-ad.model';
import { IntercomService } from '@core/services/intercom.service';
import { SnackService } from '@core/services/snack.service';
import { StorageService } from '@core/services/storage.service';
import { environment } from '@environments/environment';
import { AgentsState } from '@shared/states/agents.stats';
import { COUPON_PRICE, FEATURED_AD_PLAN_IDS, PLACE_AD_FIELDS_PROPERTIES, TOTAL_STEPS } from './place-ad.constant';
import { PlaceAdService } from './place-ad.service';

@Injectable({
	providedIn: 'root',
})
export class PlaceAdFacade {
	constructor(
		private placeAdApi: PlaceAdApi,
		private filterApi: FiltersApi,
		private placeAdState: PlaceAdState,
		private sectionsState: SectionsState,
		private placeAdService: PlaceAdService,
		private profileState: ProfileState,
		private profileApi: ProfileApi,
		private agentsState: AgentsState,
		private snackService: SnackService,
		private intercomService: IntercomService,
		private storageService: StorageService
	) {}

	private _isCompletAdSubmitted$ = new BehaviorSubject<boolean>(false);
	private _isFetchedFilterData$ = new BehaviorSubject<boolean>(false);
	private _isEditMode$ = new BehaviorSubject<boolean>(false);
	private _isAdUpdated$ = new BehaviorSubject<boolean>(false);
	private _isAllowNavigation$ = new BehaviorSubject<boolean>(false);
	private _adStand$ = new BehaviorSubject<string>('');
	private _selectedPlanTierId$ = new BehaviorSubject<string>(undefined);
	standList = [];

	get newAdId$(): Observable<string> {
		return this.placeAdState.newAdId$;
	}

	setNewAdId(newAdId: string): void {
		this.placeAdState.setNewAdId(newAdId);
	}

	getNewAdId(): string {
		return this.placeAdState.getNewAdId();
	}

	get selectedPlanTierId$(): Observable<string> {
		return this._selectedPlanTierId$.asObservable();
	}

	setSelectedPlanTierId(value: string): void {
		this._selectedPlanTierId$.next(value);
	}

	getSelectedPlanTierId(): string {
		return this._selectedPlanTierId$.getValue();
	}

	get planTierDetails$(): Observable<PlanTiers> {
		return this.placeAdState.planTierDetails$;
	}

	setPlanTierDetails(value: PlanTiers): void {
		this.placeAdState.setPlanTierDetails(value);
	}

	getPlanTierDetails(): PlanTiers {
		return this.placeAdState.getPlanTierDetails();
	}

	get adStand$(): Observable<string> {
		return this._adStand$.asObservable();
	}

	getAdStand(): string {
		return this._adStand$.getValue();
	}

	setAdStand$(value: string): void {
		this._adStand$.next(value);
	}

	onAdStandChange(currentStand: string): void {
		this.setAdStand$(currentStand);
	}

	get isAllowNavigation$(): Observable<boolean> {
		return this._isAllowNavigation$.asObservable();
	}

	setIsAllowNavigation$(value: boolean): void {
		this._isAllowNavigation$.next(value);
	}

	get isAdUpdated$(): Observable<boolean> {
		return this._isAdUpdated$.asObservable();
	}

	getIsAdUpdated(): boolean {
		return this._isAdUpdated$.getValue();
	}

	setIsAdUpdated(isUpdated: boolean): void {
		this._isAdUpdated$.next(isUpdated);
	}

	get isEditMode$(): Observable<boolean> {
		return this._isEditMode$.asObservable();
	}

	setIsEditMode$(isEditMode): void {
		this._isEditMode$.next(isEditMode);
	}

	getIsEditMode(): boolean {
		return this._isEditMode$.getValue();
	}

	get isFetchedFilterData$(): Observable<boolean> {
		return this._isFetchedFilterData$.asObservable();
	}

	setIsFetchedFilterData$(value: boolean) {
		this._isFetchedFilterData$.next(value);
	}

	get isCompletAdSubmitted$(): Observable<boolean> {
		return this._isCompletAdSubmitted$.asObservable();
	}

	setIsCompletAdSubmitted(value: boolean): void {
		this._isCompletAdSubmitted$.next(value);
	}

	getIsCompleteAdSubmitted(): boolean {
		return this._isCompletAdSubmitted$.getValue();
	}

	get placeAdControls$(): Observable<PlaceAdFieldsSections> {
		return this.placeAdState.placeAdControls$;
	}

	getPlaceAdControls(): PlaceAdFieldsSections {
		return this.placeAdState.getPlaceAdControls();
	}

	getPlaceAdControlsStep(step: string): PlaceAdFields | PlaceAdFields[] {
		return this.placeAdState.getPlaceAdControls()[step];
	}

	getPlaceAdControlStep$(step: string): Observable<PlaceAdFields[]> {
		return this.placeAdState.getPlaceAdControlsStep$(step);
	}

	updatePlaceAdControlStep$(data, property: string): void {
		this.placeAdState.updatePlaceAdControlStep$(data, property);
	}

	uploadImage(body: FormData): Observable<HttpEvent<Object>> {
		const AD_ID = this.getAdId();
		return this.placeAdApi.uploadImage(body, AD_ID);
	}

	getAgents$(): Observable<Agent[]> {
		return this.agentsState.agentList$;
	}

	getAgents(): Agent[] {
		return this.agentsState.getAgentList();
	}

	getAgentDetails(agentId): Agent {
		return this.getAgents()?.find((agent) => agent?.id === agentId);
	}

	getUser$(): Observable<ClientUser> {
		return this.profileState.user$;
	}

	onDeleteImage(imageId: string): Promise<{}> {
		const AD_ID = this.getAdId();

		return new Promise((resolve, reject) => {
			this.placeAdApi.deleteImage(AD_ID, imageId).subscribe(
				() => {
					resolve({});
				},
				(error) => {
					reject({});
				}
			);
		});
	}

	async updateFieldValues(control, params, step = 'details'): Promise<any> {
		const GET_ALL_VALUES = this.placeAdState.getPlaceAdControls()?.[step]?.map(async (filter) => {
			return filter.paramName === control.paramName && control?.parentReferenceParamName ? await this.httpGetAllValues(control, params) : filter;
		});

		return Promise.all(GET_ALL_VALUES).then((data) => {
			this.placeAdState.updatePlaceAdControlStep$(data, step);

			return data;
		});
	}

	getPlaceAdControlValues(params, step: string): Promise<void> {
		let fields: PlaceAdFields[] = [];

		const FIELD_ORDER = this.getPlaceAdPropertyFields(params.aisle)?.[step];

		FIELD_ORDER.forEach((placeAdValue) => {
			fields.push(PLACE_AD_FIELDS_PROPERTIES.find((data) => data.paramName === placeAdValue));
		});

		return this.getPlaceAdValues(fields, params, step);
	}

	async getPlaceAdValues(fields: PlaceAdFields[], params, step: string): Promise<void> {
		const GET_ALL_VALUES = fields.map(async (filter) => {
			return !!filter?.valuesEndpoint && filter?.paramName !== PlaceAdType.COUNTY_AREA ? await this.httpGetAllValues(filter, { ...params }) : filter;
		});

		return Promise.all(GET_ALL_VALUES).then((data) => {
			this.placeAdState.updatePlaceAdControlStep$(data, step);
			return null;
		});
	}

	httpGetAllValues(filter: PlaceAdFields, params): Promise<PlaceAdFields> {
		return firstValueFrom(
			this.filterApi.getFilterValue(filter.valuesEndpoint, params).pipe(
				map((response: any) => {
					if (filter?.valuesEndpoint === 'stands') this.standList = response;

					return {
						...filter,
						values: filter?.paramName === PlaceAdType.COUNTY ? response?.all : response,
					};
				})
			)
		);
	}

	//update county area value based on selected County
	async updateCountyAreaList(id: string): Promise<void> {
		const COUNTY_AREA = this.getPlaceAdControls()?.location[1];
		const GET_ALL_VALUES = await firstValueFrom(
			this.filterApi.getFilterValue(PlaceAdValuesEndPoint.COUNTY_AREA + '/' + id).pipe(
				map((response: any) => {
					return {
						...COUNTY_AREA,
						values: response,
					};
				})
			)
		);

		this.placeAdState.updatePlaceAdCountyArea$(GET_ALL_VALUES);
	}

	//to set and get ad plan price
	get adPlanPrice$(): Observable<number> {
		return this.placeAdState.adPlanPrice$;
	}

	setAdPlanPrice(amount: number): void {
		this.placeAdState.setAdPlanPrice(amount);
	}

	getAdPlanPrice(): number {
		return this.placeAdState.getAdPlanPrice();
	}

	//to get an set adId
	get adId$(): Observable<string> {
		return this.placeAdState.adId$;
	}

	setAdId(id: string): void {
		this.placeAdState.setAdId(id);
	}

	getAdId(): string {
		return this.placeAdState.getAdId();
	}

	getSection(currentSection: string): CommonItem {
		return this.sectionsState.getSection(currentSection);
	}

	getAisle(section: string, aisle: string): CommonItem {
		return this.sectionsState.getAisle(section, aisle);
	}

	setAisle(data: CommonItem) {
		this.placeAdState.setCurrentAisle(data);
	}

	setSection(data: CommonItem) {
		this.placeAdState.setCurrentSection(data);
	}

	getContactDetails$(): Observable<Contact> {
		return this.placeAdState.contactDetails$;
	}

	setContactDetails(contact: Contact): void {
		this.placeAdState.setContactDetails(contact);
	}

	createAd(file?: File): Promise<string | {} | Brochure> {
		this.placeAdService.addIndividualControlData('isDraft', '1');
		const BODY = this.placeAdService.getPlaceAdData();

		return new Promise((resolve, reject) => {
			this.placeAdApi
				.createAd(BODY)
				.pipe(
					switchMap((adDataResponse) => {
						const AD_ID = adDataResponse.id;
						this.setAdId(AD_ID);
						this.placeAdService.deleteIndividualControl(PlaceAdType.IS_DRAFT);
						this.intercomService.updateLastPostAd(AD_ID, this.profileState.getUser());

						//upload brochure if file is available
						if (file && AD_ID) {
							const BROCHURE_FORM_DATA = new FormData();
							BROCHURE_FORM_DATA.append('files', file, file.name);

							return this.placeAdApi.uploadBrochure(AD_ID, BROCHURE_FORM_DATA).pipe(catchError((err) => of('brochure')));
						} else {
							return of({});
						}
					}),
					catchError(() => of('createAd'))
				)
				.subscribe({
					next: (response) => {
						this.placeAdService.setStepsEnableStatus(3);
						this.placeAdService.setTabIndex(3);
						resolve(response);
					},
					error: (error) => {
						reject(error);
					},
				});
		});
	}

	updateAd(index: number): Promise<{}> {
		const BODY = this.placeAdService.getPlaceAdData();

		return new Promise((resolve, reject) => {
			this.placeAdApi.updateAd(this.getAdId(), BODY).subscribe({
				next: (data) => {
					//if we know which no the correct tab index

					this.placeAdService.directTabIndexUpdate(index);

					if (data?.id) {
						this.setNewAdId(data?.id);
					}

					this.setIsAdUpdated(true);

					resolve({});
				},
				error: (error) => {
					reject(error);
				},
			});
		});
	}

	updateYoutubeUrlData(): Promise<{}> {
		const BODY = this.placeAdService.getPlaceAdData();

		return new Promise((resolve, reject) => {
			this.placeAdApi.updateAd(this.getAdId(), BODY).subscribe({
				next: (data) => {
					resolve({});
				},
				error: (error) => {
					reject(error);
				},
			});
		});
	}

	editAd(): void {
		const AD_ID = this.getAdId();

		this.placeAdApi.editAd(AD_ID).subscribe((data) => {
			this.placeAdState.setEditAdData(data);
		});
	}

	getEditAdData(): EditAdResponseData {
		return this.placeAdState.getEditAdData();
	}

	getEditAdData$(): Observable<EditAdResponseData> {
		return this.placeAdState.editAdData$;
	}

	setEditAdData(data: EditAdResponseData) {
		this.placeAdState.setEditAdData(data);
	}

	fetchAdData(): void {
		const AD_ID = this.getAdId();

		this.placeAdApi.getAd(AD_ID).subscribe((data) => {
			this.placeAdState.setAdData(data);
		});
	}

	reOrderImage(images: ReOrderImage[]): Promise<{}> {
		const AD_ID = this.getAdId();

		return new Promise((resolve, reject) => {
			this.placeAdApi.reOrderImage(AD_ID, images).subscribe({
				next: () => {
					const NEXT_TAB_INDEX = this.placeAdService.getTabIndex() + 1;
					this.placeAdService.directTabIndexUpdate(NEXT_TAB_INDEX);
					this.setIsAdUpdated(true);

					resolve({});
				},
				error: (error) => {
					reject(error);
				},
			});
		});
	}

	setAdData(adData: Ad) {
		this.placeAdState.setAdData(adData);
	}

	getAdData$(): Observable<Ad> {
		return this.placeAdState.adData$;
	}

	getAdData(): Ad {
		return this.placeAdState.getAdData();
	}

	completedAd(): Observable<{}> {
		const AD_ID = this.getAdId();
		const BODY = this.placeAdService.getPlaceAdData();
		return this.placeAdApi.completeAd(AD_ID, BODY);
	}

	getCurrentSection(): CommonItem {
		return this.placeAdState.getCurrentSection();
	}

	getCurrentAisle(): CommonItem {
		return this.placeAdState.getCurrentAisle();
	}

	getCurrentSection$(): Observable<CommonItem> {
		return this.placeAdState.getCurrentSection$();
	}

	getCurrentAisle$(): Observable<CommonItem> {
		return this.placeAdState.getCurrentAisle$();
	}

	get editAdData$(): Observable<EditAdResponseData> {
		return this.placeAdState.editAdData$;
	}

	resetPlaceAd(): void {
		this.setAdId(null);
		this.placeAdService.resetPlaceAdData();
		this.placeAdService.setTabIndex(0);
		this.placeAdService.setStepsEnableStatus(0);
		this.setEditAdData(null);
		this.setIsFetchedFilterData$(false);
		this.setIsEditMode$(false);
		this.placeAdState.setAdData(null);
		this.setIsAdUpdated(false);
		this.setSection(null);
		this.setAisle(null);
		this.setIsAllowNavigation$(false);
		this.placeAdState.setPlaceAdControls(null);
		this.placeAdService.setTotalTabs(8);
		this.placeAdState.setIsFeaturedAd(false);
		this.setAdPlanPrice(0);
		this.setCouponCode(null);
		this.setPlanTierDetails(undefined);
		this.storageService.removeItem('agentId');
		this.setNewAdId('');
	}

	completeAdOnPay(): Promise<{}> {
		return new Promise((resolve, reject) => {
			this.completedAd().subscribe(
				() => {
					resolve({});
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	individualFeatureAdPayment(): Promise<PaymentResponse | {}> {
		const BODY = this.placeAdService.getPlaceAdData();

		return new Promise((resolve, reject) => {
			this.placeAdApi
				.updateAd(this.getAdId(), BODY)
				.pipe(
					switchMap((data) => {
						const EDIT_AD_DATA = this.getEditAdData();

						//if already paid and ad status not sold skip payment
						if (EDIT_AD_DATA?.adPayment?.status === AdPaymentStatus.PAID && EDIT_AD_DATA?.adStatus?.status !== AdStatus.SOLD) {
							return of({});
						} else {
							if (this.getCouponCode() && (!this.getIsFeaturedAd() || this.getSellerTypeId() === SellerType.BUSINESS)) {
								//if coupon code is valid apply coupon and make ad complete
								const COUPON_BODY: Coupon = {
									coupon: this.getCouponCode(),
									adId: this.getAdId(),
									isFeatured: this.getIsFeaturedAd() ? '1' : '0',
								};

								return this.placeAdApi.couponPayment(COUPON_BODY).pipe(
									switchMap(() => this.completedAd()),
									catchError((err) => {
										this.snackService.open({
											data: { message: err.error.error.id },
											panelClass: 'snackbar-error',
										});
										throw new Error(err);
									})
								);
							} else {
								return this.placeAdPayment(); //otherwise call stripe payment
							}
						}
					})
				)
				.subscribe(
					(data) => {
						resolve(data);
					},
					(error) => {
						reject(error);
					}
				);
		});
	}

	businessFeaturedAdComplete(): Promise<{}> {
		const BODY = this.placeAdService.getPlaceAdData();

		return new Promise((resolve, reject) => {
			this.placeAdApi
				.updateAd(this.getAdId(), BODY)
				.pipe(
					switchMap((data) => {
						if (this.getEditAdData()?.adPayment?.status === AdPaymentStatus.PAID) {
							return this.completedAd();
						} else {
							const BODY = {
								coupon: this.getCouponCode(),
								adId: this.getAdId(),
								isFeatured: this.getIsFeaturedAd() ? '1' : '0',
							};

							return this.placeAdApi.couponPayment(BODY).pipe(
								switchMap(() => {
									return this.completedAd();
								}),
								catchError((err) => {
									this.snackService.open({
										data: { message: err.error.error.id },
										panelClass: 'snackbar-error',
									});
									throw new Error(err);
								})
							);
						}
					})
				)
				.subscribe(
					(data) => {
						resolve({});
					},
					(error) => {
						reject(error);
					}
				);
		});
	}

	getAdDetails(editDetails: EditAdResponseData = null, section = '', aisle = ''): void {
		let params: Params = {
			section: section,
			aisle: aisle,
		};

		if (editDetails) {
			this.setIsEditMode$(true);

			params = {
				section: editDetails?.section?.reference,
				aisle: editDetails?.aisle?.reference,
				stand: editDetails?.stand?.reference,
			};

			this.placeAdService.addIndividualControlData(PlaceAdType.SECTION, editDetails?.section?.id);
			this.placeAdService.addIndividualControlData(PlaceAdType.AISLE, editDetails?.aisle?.id);
			this.placeAdService.addIndividualControlData(PlaceAdType.SELLER_TYPE, editDetails?.sellerType?.id);
			this.placeAdService.setCurrentSellerId(editDetails?.sellerType?.id);
			const IS_FEATURED_AD = FEATURED_AD_PLAN_IDS.includes(<PlaceAdPlansId>editDetails?.adPayment?.planTier?.id);
			this.setIsFeaturedAd(IS_FEATURED_AD);
			this.setSection(editDetails?.section);
			this.setAisle(editDetails?.aisle);

			this.placeAdService.setTotalTabs(editDetails?.stand?.id === '5' ? 7 : TOTAL_STEPS(editDetails?.section?.reference));

			if (editDetails?.adPayment?.couponCode) {
				this.setCouponCode(editDetails.adPayment.couponCode);
			}

			if (editDetails?.adPayment?.status === AdPaymentStatus.PAID && editDetails?.adStatus?.status !== AdStatus.SOLD) {
				this.setAdPlanPrice(COUPON_PRICE);
			}
		} else {
			this.setIsEditMode$(false);
		}

		const AISLE_REFERENCE = this.getCurrentAisle()?.reference;

		//load roomtypes data
		if (AISLE_REFERENCE === AisleType?.SHARE_RESIDENTIAL) {
			this.loadRoomTypesData();
		}

		const STEPS = AISLE_REFERENCE === AisleType?.RENT_COMMERCIAL ? ['location', 'details', 'amenities', 'rentalPeriods'] : ['location', 'details', 'amenities'];

		let count = 0;

		STEPS.forEach((step) => {
			this.getPlaceAdControlValues(params, step).then(() => {
				count++;

				// to check all the Api datas are fetched
				if (AISLE_REFERENCE === AisleType?.RENT_COMMERCIAL ? count === 4 : count === 3) {
					this.setIsFetchedFilterData$(true);
				}
			});
		});
	}

	//to update the enable status of tab based on edit ad data
	updateTabOnEditData(editData: EditAdResponseData): void {
		if (editData) {
			const TOTAL_TABS = this.placeAdService.getTotalTabs();

			let updatedIndex = 2;

			if (editData?.images?.length > 0) {
				updatedIndex = TOTAL_TABS - 2;
			} else if (editData?.description) {
				updatedIndex = TOTAL_TABS - 3;
			} else if (editData?.propertyExtras?.length > 0) {
				updatedIndex = 3;
			} else {
				updatedIndex = 2;
			}

			this.placeAdService.setStepsEnableStatus(updatedIndex);
			this.placeAdService.setTabIndex(0);
			this.placeAdService.setIsNextButtonDisabled(false);
		}
	}

	fetchLatestAdData$(): Observable<Ad> {
		const AD_ID = this.getNewAdId() || this.getAdId();
		return this.placeAdApi.getAd(AD_ID);
	}

	//to update seller data on ad details and fetch latest ad detail if on review page or photo page
	updateSellerTypeDetails(sellerTypeId: string): void {
		if (this.getAdId()) {
			const BODY = this.placeAdService.getPlaceAdData();

			this.placeAdApi
				.updateAd(this.getAdId(), BODY)
				.pipe(
					switchMap(() => {
						this.setIsAdUpdated(true);

						this.placeAdService.setCurrentSellerId(sellerTypeId);

						const TOTAL_TABS = this.placeAdService.getTotalTabs();

						const PHOTO_TAB = TOTAL_TABS === PlaceAdTabIndex.TAB_8 ? TOTAL_TABS - PlaceAdTabIndex.TAB_3 : TOTAL_TABS - PlaceAdTabIndex.TAB_2;
						const CURRENT_TAB_INDEX = this.placeAdService.getTabIndex();

						if (CURRENT_TAB_INDEX === TOTAL_TABS || CURRENT_TAB_INDEX === PHOTO_TAB) {
							return this.fetchLatestAdData$();
						}

						return of(null);
					})
				)
				.subscribe((adData) => {
					if (adData) {
						this.setAdData(adData);
						this.setIsAdUpdated(false);
					}
				});
		}
	}

	//to fetch roomtypes from API
	loadRoomTypesData(): void {
		if (!this.placeAdState.getRoomTypes()?.length) {
			this.filterApi.getFilterValue('room-types', {}).subscribe((data) => {
				this.placeAdState.setRoomTypes(data);
			});
		}
	}

	getRoomTypes$(): Observable<CommonItem[]> {
		return this.placeAdState.roomTypes$;
	}

	//to get the place ad fields for diiffernet type of aisle
	getPlaceAdPropertyFields(aisle: string): {
		location: PlaceAdType[];
		price: PlaceAdType[];
		details: PlaceAdType[];
		amenities: PlaceAdType[];
		rentalPeriods?: PlaceAdType[];
	} {
		let details: PlaceAdType[] = [];

		switch (aisle) {
			case AisleType.RENT_RESIDENTIAL:
				details = [
					PlaceAdType.PARKING_ACCESS,
					PlaceAdType.PARKING_COUNT,
					PlaceAdType.STAND,
					PlaceAdType.SINGLE_BEDROOM_COUNT,
					PlaceAdType.DOUBLE_BEDROOM_COUNT,
					PlaceAdType.SUITE_BEDROOM_COUNT,
					PlaceAdType.BATHROOM_COUNT,
					PlaceAdType.PROPERTY_SIZE,
					PlaceAdType.LEASE_TERM,
					PlaceAdType.AVAILABLE_FROM,
					PlaceAdType.FURNISHING,
					PlaceAdType.BER_TYPE,
					PlaceAdType.BER_NUMBER,
				];
				break;

			case AisleType.SHARE_RESIDENTIAL:
				details = [
					PlaceAdType.STAND,
					PlaceAdType.LEASE_TERM,
					PlaceAdType.AVAILABLE_FROM,
					PlaceAdType.OTHER_TENANTS,
					PlaceAdType.OWNER_OCCUPIED,
					PlaceAdType.TEANANT_PREFERENCE,
					PlaceAdType.ONEPLUS_PERSON_ALLOWED,
				];
				break;

			case AisleType.RENT_COMMERCIAL:
				details = [
					PlaceAdType.STAND,
					PlaceAdType.PROPERTY_SIZE,
					PlaceAdType.PROPERTY_UNIT,
					PlaceAdType.LEASE_TERM,
					PlaceAdType.AVAILABLE_FROM,
					PlaceAdType.BER_TYPE,
					PlaceAdType.BER_NUMBER,
				];
				break;

			case AisleType.SALE_RESIDENTIAL:
				details = [
					PlaceAdType.STAND,
					PlaceAdType.SINGLE_BEDROOM_COUNT,
					PlaceAdType.DOUBLE_BEDROOM_COUNT,
					PlaceAdType.SUITE_BEDROOM_COUNT,
					PlaceAdType.BATHROOM_COUNT,
					PlaceAdType.PROPERTY_SIZE,
					PlaceAdType.PROPERTY_UNIT,
					PlaceAdType.BER_TYPE,
					PlaceAdType.BER_NUMBER,
					PlaceAdType.TAX_DESIGNATION,
				];
				break;

			case AisleType.SALE_COMMERCIAL:
				details = [PlaceAdType.STAND, PlaceAdType.PROPERTY_SIZE, PlaceAdType.PROPERTY_UNIT, PlaceAdType.BER_TYPE, PlaceAdType.BER_NUMBER];
				break;

			default:
				details = [
					PlaceAdType.STAND,
					PlaceAdType.PROPERTY_SIZE,
					PlaceAdType.AVAILABLE_FROM,
					PlaceAdType.LEASE_TERM,
					PlaceAdType.FURNISHING,
					PlaceAdType.BEDROOM_COUNT,
					PlaceAdType.BATHROOM_COUNT,
				];

				break;
		}

		return {
			location: [PlaceAdType.COUNTY, PlaceAdType?.COUNTY_AREA],
			price: [PlaceAdType?.PRICE],
			details: details,
			amenities: [PlaceAdType.PROPERTY_EXTRAS],
			rentalPeriods: [PlaceAdType.RENTAL_PERIOD],
		};
	}

	getAiseList$(section: string): Observable<Aisle[]> {
		return this.sectionsState.sectionList$.pipe(
			filter((sectionList: Section[]) => !!sectionList?.length),
			map(() => {
				const AISLE_LIST = this.sectionsState.getSection(section).aisles;
				let AISLE_ORDER;
				let MAPPER;

				switch (section) {
					case SectionType.PROPERTIES_FOR_RENT:
						{
							AISLE_ORDER = [AisleType.SHARE_RESIDENTIAL, AisleType.RENT_RESIDENTIAL, AisleType.RENT_COMMERCIAL];

							MAPPER = {
								[AisleType.SHARE_RESIDENTIAL]: { icon: 'meeting_room', description: this.sectionsState.getStands(section, AisleType.SHARE_RESIDENTIAL) },
								[AisleType.RENT_RESIDENTIAL]: { icon: 'home_work', description: this.sectionsState.getStands(section, AisleType.RENT_RESIDENTIAL) },
								[AisleType.RENT_COMMERCIAL]: { icon: 'corporate_fare', description: this.sectionsState.getStands(section, AisleType.RENT_COMMERCIAL) },
							};
						}
						break;
					case SectionType.PROPERTIES_FOR_SALE: {
						AISLE_ORDER = [AisleType.SALE_RESIDENTIAL, AisleType.SALE_COMMERCIAL];

						MAPPER = {
							[AisleType.SALE_RESIDENTIAL]: { icon: 'meeting_room', description: this.sectionsState.getStands(section, AisleType.SALE_RESIDENTIAL) },
							[AisleType.SALE_COMMERCIAL]: { icon: 'home_work', description: this.sectionsState.getStands(section, AisleType.SALE_COMMERCIAL) },
						};
					}
				}

				return AISLE_LIST.map((aisle) => {
					return {
						...aisle,
						icon: MAPPER[aisle.reference].icon,
						description: MAPPER[aisle.reference].description,
					};
				}).sort((aisleA: Aisle, aisleB: Aisle) => AISLE_ORDER.indexOf(aisleA.reference as AisleType) - AISLE_ORDER.indexOf(aisleB.reference as AisleType));
			})
		);
	}

	get isFeaturedAd$(): Observable<boolean> {
		return this.placeAdState.isFeaturedAd$;
	}

	setIsFeaturedAd(value: boolean): void {
		this.placeAdState.setIsFeaturedAd(value);
	}

	getIsFeaturedAd(): boolean {
		return this.placeAdState.getIsFeaturedAd();
	}

	getcouponCode$(): Observable<string> {
		return this.placeAdState.couponCode$;
	}

	setCouponCode(coupon: string): void {
		this.placeAdState.setCouponCode(coupon);
	}

	getCouponCode(): string {
		return this.placeAdState.getCouponCode();
	}

	loadPlanTiers(): Promise<PlanTiers> {
		const PARAMS = {
			aisleId: this.getCurrentAisle()?.id,
			sellerTypeId: '1',
		};

		return new Promise((resolve) => {
			this.placeAdApi.getPlanTiers(PARAMS).subscribe((planTiers: PlanTiers) => {
				this.setPlanTierDetails(planTiers);

				resolve(planTiers);
			});
		});
	}

	placeAdPayment(): Observable<PaymentResponse> {
		const AD_ID = this.getAdId();

		const BODY: PlaceAdPaymentParams = {
			planTierId: this.getSelectedPlanTierId(),
			aisleId: this.getCurrentAisle()?.id,
			adId: this.getAdId(),
			successUrl: `${environment.appUrl}/` + 'ad-submitted',
			cancelUrl: `${environment.appUrl}/` + `edit-ad/${AD_ID}`,
		};

		return this.placeAdApi.stripePayment(BODY);
	}

	individualStandardAdPayment(): Promise<PaymentResponse | {}> {
		//if coupon applied do coupon payment and complete ad
		if (this.getCouponCode() && (!this.getIsFeaturedAd() || this.getSellerTypeId() === SellerType.BUSINESS)) {
			const COUPON_BODY: Coupon = { coupon: this.getCouponCode(), adId: this.getAdId(), isFeatured: this.getIsFeaturedAd() ? '1' : '0' };

			return firstValueFrom(
				this.placeAdApi.couponPayment(COUPON_BODY).pipe(
					switchMap(() => this.completedAd()),
					catchError((err) => {
						this.snackService.open({
							data: { message: err.error.error.id },
							panelClass: 'snackbar-error',
						});
						throw new Error(err);
					})
				)
			);
		} else {
			// else call stripe payment
			return firstValueFrom(this.placeAdPayment());
		}
	}

	verifyPayment(body: VerifyPayment): Promise<{}> {
		return new Promise((resolve, reject) => {
			this.placeAdApi
				.completePayment(body)
				.pipe(
					catchError((err) => {
						this.snackService.open({
							data: { message: err.error.error.id },
							panelClass: 'snackbar-error',
						});
						throw new Error(err);
					})
				)
				.subscribe(
					() => {
						resolve({});
					},
					(error) => {
						reject(error);
					}
				);
		});
	}

	couponPayment(body: Coupon): Promise<{ adId: string }> {
		return firstValueFrom(this.placeAdApi.couponPayment({ ...body, isFeatured: this.getIsFeaturedAd() ? '1' : '0' }));
	}

	businessAdWithoutBadges(): Promise<{}> {
		const BODY = {
			coupon: this.getCouponCode(),
			adId: this.getAdId(),
			isFeatured: this.getIsFeaturedAd() ? '1' : '0',
		};

		if (this.getEditAdData()?.adPayment?.status === AdPaymentStatus.PAID) {
			//if the ad payment status is paid and got new Ad id (ie: ad got updated)skip coupon payment and make ad complete
			return firstValueFrom(this.completedAd());
		} else {
			return firstValueFrom(
				this.placeAdApi.couponPayment(BODY).pipe(
					switchMap(() => {
						return this.completedAd();
					})
				)
			);
		}
	}

	//verify coupon is valid and return if its featured plan or not
	verifyCoupon(body: Coupon, showSnakcbar = true): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.placeAdApi.verifyCoupon(body).subscribe({
				next: () => {
					this.setIsFeaturedAd(false);
					this.setCouponCode(body.coupon);
					this.setAdPlanPrice(COUPON_PRICE);
					resolve(false);

					if (showSnakcbar) {
						this.snackService.open({
							data: { translationKey: 'n3347', icon: 'check_circle' },
							panelClass: 'snackbar-success',
						});
					}
				},
				error: (error) => {
					this.setCouponCode(null);
					this.setSelectedPlanTierId(PlaceAdPlansId.FEATURED_AD);
					this.setAdPlanPrice(+this.getPlanTierDetails()?.individualAccount[1]?.features[0]?.price?.price);
					this.setIsFeaturedAd(false);
					reject(error);
				},
			});
		});
	}

	uploadBrochure(adId: string, FILE: File): Promise<Brochure> {
		const BROCHURE_FORM_DATA = new FormData();
		BROCHURE_FORM_DATA.append('files', FILE, FILE.name);

		return firstValueFrom(this.placeAdApi.uploadBrochure(adId, BROCHURE_FORM_DATA));
	}

	removeBrochure(): Promise<{}> {
		return firstValueFrom(this.placeAdApi.deleteBrochure(this.getAdId()));
	}

	uploadAdImageAfterRotate(adId: string, imageId: string, formData): Observable<{}> {
		return this.placeAdApi.uploadAdImageAfterRotate(adId, imageId, formData);
	}

	loadUser(): void {
		this.profileApi.getProfile().subscribe((response: { data: ClientUser }) => {
			this.profileState.updateUser(response?.data);
		});
	}

	getUser(): ClientUser {
		return this.profileState.getUser();
	}

	updateUser(user: ClientUser): void {
		this.profileState.updateUser(user);
	}

	getEricode(search: string, params: string): Observable<Ericode> {
		return this.placeAdApi.getEriCode(search, params);
	}

	getSectionList$(): Observable<Section[]> {
		return this.sectionsState.sectionList$;
	}

	fetchPreviousContactDetails() {
		this.placeAdApi.getLastAdDetails().subscribe((adData) => {
			this.setContactDetails(adData?.contact);
		});
	}

	getSellerTypeId(): string {
		return this.placeAdService.getCurrentSellerId();
	}

	getFeaturedAdCount$(params: Params): Observable<PageMetaData> {
		return this.placeAdApi.getFeaturedAdCount(params);
	}
}
