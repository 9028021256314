import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { Ad, CommonItem, Contact, EditAdResponseData, PlaceAdFields, PlaceAdFieldsSections, PlanTiers } from '@core/models';

@Injectable({
	providedIn: 'root',
})
export class PlaceAdState {
	private readonly _placeAdControls$ = new BehaviorSubject<PlaceAdFieldsSections>(null);
	private readonly _adId$ = new BehaviorSubject<string>('');
	private readonly _adPlanPrice$ = new BehaviorSubject<number>(null);
	private readonly _currentSection$ = new BehaviorSubject<CommonItem>(null);
	private readonly _currentAisle = new BehaviorSubject<CommonItem>(null);
	private readonly _editAdData$ = new BehaviorSubject<EditAdResponseData>(null);
	private readonly _adData$ = new BehaviorSubject<Ad>(null);
	private readonly _roomTypes$ = new BehaviorSubject<CommonItem[]>(null);
	private readonly _isFeaturedAd$ = new BehaviorSubject<boolean>(false);
	private readonly _couponCode$ = new BehaviorSubject<string>(undefined);
	private readonly _planTierDetails$ = new BehaviorSubject<any>(undefined);
	private readonly _contactDetails$ = new BehaviorSubject<Contact>(undefined);
	//ad id generated while editing live ad
	private readonly _newAdId$ = new BehaviorSubject<string>('');

	get newAdId$(): Observable<string> {
		return this._newAdId$.asObservable();
	}

	setNewAdId(newAdId: string): void {
		this._newAdId$.next(newAdId);
	}

	getNewAdId(): string {
		return this._newAdId$.getValue();
	}

	get contactDetails$(): Observable<Contact> {
		return this._contactDetails$;
	}

	setContactDetails(contact: Contact): void {
		this._contactDetails$.next(contact);
	}

	get planTierDetails$(): Observable<PlanTiers> {
		return this._planTierDetails$.asObservable();
	}

	setPlanTierDetails(value: PlanTiers): void {
		this._planTierDetails$.next(value);
	}

	getPlanTierDetails(): PlanTiers {
		return this._planTierDetails$.getValue();
	}

	get couponCode$(): Observable<string> {
		return this._couponCode$.asObservable();
	}

	getCouponCode(): string {
		return this._couponCode$.getValue();
	}

	setCouponCode(coupon: string): void {
		this._couponCode$.next(coupon);
	}

	get isFeaturedAd$(): Observable<boolean> {
		return this._isFeaturedAd$.asObservable();
	}

	getIsFeaturedAd(): boolean {
		return this._isFeaturedAd$.getValue();
	}

	setIsFeaturedAd(value: boolean): void {
		this._isFeaturedAd$.next(value);
	}

	get roomTypes$(): Observable<CommonItem[]> {
		return this._roomTypes$.asObservable();
	}

	getRoomTypes(): CommonItem[] {
		return this._roomTypes$.getValue();
	}

	setRoomTypes(data: CommonItem[]): void {
		this._roomTypes$.next(data);
	}

	//to set and get ad plan price
	get adPlanPrice$(): Observable<number> {
		return this._adPlanPrice$.asObservable();
	}

	setAdPlanPrice(amount: number): void {
		this._adPlanPrice$.next(amount);
	}

	getAdPlanPrice(): number {
		return this._adPlanPrice$.getValue();
	}

	//to get an set adId
	get adId$(): Observable<string> {
		return this._adId$.asObservable();
	}

	setAdId(id: string): void {
		this._adId$.next(id);
	}

	getAdId(): string {
		return this._adId$.getValue();
	}

	//to fetch all place ad controls
	get placeAdControls$(): Observable<PlaceAdFieldsSections> {
		return this._placeAdControls$.asObservable();
	}

	getPlaceAdControls(): PlaceAdFieldsSections {
		return this._placeAdControls$.getValue();
	}

	setPlaceAdControls(data: PlaceAdFieldsSections): void {
		this._placeAdControls$.next(data);
	}

	getPlaceAdControlsStep$(step: string): Observable<PlaceAdFields[]> {
		return this._placeAdControls$.pipe(map((data) => data?.[step]));
	}

	//to update countyArea values based on county id
	updatePlaceAdCountyArea$(data: PlaceAdFields): void {
		const CURRENT_DATA = this.getPlaceAdControls();
		const STEP_DATA = this.getPlaceAdControls()['location'];
		const UPDATED_DATA = { ...CURRENT_DATA, location: { ...STEP_DATA, 1: data } };

		this._placeAdControls$.next(UPDATED_DATA);
	}

	//to update particular section data of _placeAdControls of type PlaceFieldSections
	updatePlaceAdControlStep$(data: PlaceAdFields[], step: string): void {
		const CURRENT_DATA = this.getPlaceAdControls();
		const UPDATED_DATA = { ...CURRENT_DATA, [step]: data };
		this._placeAdControls$.next(UPDATED_DATA);
	}

	getCurrentSection(): CommonItem {
		return this._currentSection$.getValue();
	}

	setCurrentSection(data: CommonItem): void {
		this._currentSection$.next(data);
	}

	getCurrentAisle(): CommonItem {
		return this._currentAisle.getValue();
	}

	setCurrentAisle(data: CommonItem): void {
		this._currentAisle.next(data);
	}

	setEditAdData(data: EditAdResponseData): void {
		this._editAdData$.next(data);
	}

	get editAdData$(): Observable<EditAdResponseData> {
		return this._editAdData$.asObservable();
	}

	getEditAdData(): EditAdResponseData {
		return this._editAdData$.getValue();
	}

	setAdData(data: Ad): void {
		this._adData$.next(data);
	}

	get adData$(): Observable<Ad> {
		return this._adData$.asObservable();
	}

	getAdData(): Ad {
		return this._adData$.getValue();
	}

	getCurrentSection$(): Observable<CommonItem> {
		return this._currentSection$;
	}

	getCurrentAisle$(): Observable<CommonItem> {
		return this._currentAisle;
	}
}
